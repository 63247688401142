<p-toast position="top-center"></p-toast>

<div class="container-auth">
    <div class="wrapper">

        <div class="login box__auth">
            <div class="content-heading">
                <div class="y-style">
                    <div class="logo-auth mb-5 mt-2">
                        <img src=".../../../../../assets/images/LOGO_ERSYS-WHITE.png" alt="logo">
                    </div>
                    <div class="welcome">
                        <h2>
                            Qui êtes-vous ?
                        </h2>
                        <p>
                            Identifiez-Vous avec votre adresse mail.
                        </p>
                    </div>
                </div>
            </div>

            <div class="content-form">
                <div class="y-style">
                    <form [formGroup]=form>
                        <div>
                            <label for="email">Email</label>
                            <input type="email" placeholder="Entrez votre adresse email" id="email" formControlName="email" />
                        </div>

                        <button *ngIf=!isLoader type="button" (click)="sendResetPasswordEmail()">Envoyer</button>
                        <app-loader *ngIf=isLoader></app-loader>
                        <div class="forgot">
                            <a [routerLink]="['/login']">Connectez-Vous</a>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </div>
</div>