import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HandlerErrorService } from 'src/app/services/handler-error/handler-error.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { C_LocalStorage } from 'src/app/utils/constant.util';
import { E_toast } from 'src/app/utils/enum.util';
import { I_InputError } from 'src/app/utils/type.util';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent {
  constructor(private router: Router,
    private handlerErrorService: HandlerErrorService,
    private messageService: MessageService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    const email = this.localStorageService.value(C_LocalStorage.reset)?.email
    if (email) this.resetPassword.patchValue({ email })
  }

  isLoader = false
  inputList: I_InputError[] = [
    { name: "pwd", label: "mot de passe" },
    { name: "pwd_confirm", message: "Confirmer votre mot de passe" },
    { name: "email", label: "adresse mail" },
  ]

  resetUserInformation: any = {}
  resetPassword = new FormGroup({
    pwd: new FormControl("", Validators.required),
    pwd_confirm: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email])
  })

  displayToastError(message: string) {
    this.messageService.add({ severity: E_toast.error, summary: 'ERREUR', detail: message });
  }

  displayToastSuccess(message: string) {
    this.messageService.add({ severity: E_toast.success, summary: 'SUCCES', detail: message });
  }

  async runResetPassword() {
    try {
      this.isLoader = true
      const { email, pwd, pwd_confirm } = this.resetPassword.value
      this.handlerErrorService.validateEmailFormat(this.resetPassword)
      this.handlerErrorService.validateInputs(this.inputList, this.resetPassword)
      if (email && pwd && pwd_confirm) {
        this.handlerErrorService.comparePassword(pwd, pwd_confirm)
        await this.authService.resetPassword({ email, pwd, pwd_confirm })
        this.isLoader = false
        this.resetPassword.reset()
        this.localStorageService.remove(C_LocalStorage.reset)
        this.router.navigate(['login'])
      }
    } catch (error: any) {
      this.isLoader = false
      if (error.error) {
        this.displayToastError(error.error.detail.message)
        return
      }
      this.displayToastError(error as string)
    }
  }
}
