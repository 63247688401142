export const C_RegexEmail = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
export const C_pageSize = 10
export const C_Session = "2023"
export const MATRICULE_REGEX = /^[0-9]{8}[A-Z]{1}/
export const PHONE_NUMBER_REGEX = /^0[1,5,7]{1}[0-9]{8}$/
export const PROFIL_BASE_URL = `http://159.89.107.54:8006/ersys.concours.competition.candidate.image.uri/`

export const C_Files = "files"
export const C_FilesCSV = "files"
export const C_Loader = "../../../../assets/images/loader.gif"

export const C_NatureRecette = {
    freeCandidate: "FRAIS_EXAMEN"
}

export const C_LocalStorage = {
    userConected: "userConected",
    token: "token",
    reset: "reset",
    updateUser: "updateUser",
    updateCompetitionCandidate: "updateCompetitionCandidate",
}


export const C_Color = {
    primary: "#1C2E82",
    orange: "#F77F00",
    green: "green"
}

export const TENSION = 0.4

 export const DECISION: any = {
    AD: "ADMIS",
    RD: "REDOUBLE",
    RP: "REPORT",
    FC: "FIN CYCLE",
    EX: "EXCLU",
    ECE: "EXCLU EN CAS D'ECHEC",
    RCE: "REDOUBLE EN CAS D'ECHEC",
  }
