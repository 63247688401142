import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { C_pageSize } from 'src/app/utils/constant.util';
import { C_ApiKey_SHARE, C_ApiShareBaseUrl } from 'env/env';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) { }

  getGuestUsers(p_currentpage: number = 0, p_pagesize: number = C_pageSize): Observable<any> {
    return this.httpClient.get(`${C_ApiShareBaseUrl}.guest.user.role/?apikey=${C_ApiKey_SHARE}&p_currentpage=${p_currentpage}&p_pagesize=${p_pagesize}`);
  }
}
