import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, catchError, pipe, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken()
    const isLogged = this.authService.isLogged()
    if (isLogged) {
      const cloneRequest = request.clone({
        headers: request.headers.set("Authorization", `bearer ${token}`)
      })
      return next.handle(cloneRequest).pipe(
        catchError(error => {
          if (error.status === 400) this.authService.clearToken()
          return throwError(() => error)
        })
      )
    }

    return next.handle(request)
  }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}