export const C_ApiKey = "943dffab-b019-4ea2-b976-2f690b262a8c"
export const C_ApiKey_SHARE = "68b2d8b3-cf91-48cd-982d-c975cf41c289"
export const C_PAY_APY_KEY= "aa78eec8-9c3d-4fcb-be54-b8baeb280f2d"

const BASE_ADDRESS_ADMINSPACE = "https://backendapi.ersys-ci.net/admin"
const BASE_ADDRESS_SHARERESSOURCE = "https://backendapi.ersys-ci.net/share"

export const C_ApiBaseUrl = `${BASE_ADDRESS_ADMINSPACE}/ersys`
export const C_ApiShareBaseUrl = `${BASE_ADDRESS_SHARERESSOURCE}/ersys.share` 

export const C_ApiBaseUrlV2 = `${BASE_ADDRESS_ADMINSPACE}/v2/ersys`
export const C_Url_Image = `${BASE_ADDRESS_ADMINSPACE}/ersys.registration.image.uri`
