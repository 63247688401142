import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { I_ResetParam, I_UserConnected } from 'src/app/utils/type.util';
import { C_ApiBaseUrl, C_ApiKey } from 'env/env';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { C_LocalStorage } from 'src/app/utils/constant.util';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    private router: Router,
  ) { }

  getUserConnected(key: string): I_UserConnected {
    return this.localStorageService.value(key)
  }

  async resetPassword(resetParams: I_ResetParam): Promise<any> {
    try {
      const httpResponse = this.httpClient.post(`${C_ApiBaseUrl}.reset.user.password/?apikey=${C_ApiKey}`, resetParams);
      const resetPasswordResponse = await firstValueFrom(httpResponse)
      return resetPasswordResponse
    } catch (error) {
      throw error
    }
  }
  async sendResetPasswordEmail(email: string): Promise<any> {
    try {
      const httpResponse = this.httpClient.post(`${C_ApiBaseUrl}.verify.mail/?apikey=${C_ApiKey}`, { email });
      const EmailSendResponse = await firstValueFrom(httpResponse)
      return EmailSendResponse
    } catch (error) {
      throw error
    }
  }

  async verifyOTP({ otp, email }: { otp: string, email: string }): Promise<any> {
    try {
      const httpResponse = this.httpClient.post(`${C_ApiBaseUrl}.otp.verification/`, { otp, email });
      const response = await firstValueFrom(httpResponse)
      return response
    } catch (error) {
      throw error
    }
  }

  isLogged(): boolean {
    return !!this.getToken()
  }

  getToken(): string | null {
    const token = this.localStorageService.value(C_LocalStorage.token)
    return token
  }

  clearToken(): void {
    this.localStorageService.remove(C_LocalStorage.token)
    this.router.navigate(['login'])
  }

  autoLogoutIfTokenExpired(timeExpired: number): void {
    if (timeExpired * 1000 < Date.now()) {
      this.clearToken()
    }
  }

  autoLogout(): void {
    if (!this.isLogged()) {
      this.clearToken()
    }
  }

}
