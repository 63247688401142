import { Injectable } from '@angular/core';
import { MATRICULE_REGEX, PHONE_NUMBER_REGEX } from 'src/app/utils/constant.util';
import { I_InputError } from 'src/app/utils/type.util';

@Injectable({
  providedIn: 'root'
})
export class HandlerErrorService {

  validateInputs(inputList: I_InputError[], formName: any): void {
    inputList.forEach((input: I_InputError) => {
      const invalidInput = formName.get(input.name)?.invalid
      if (invalidInput) {
        const errorMessage = input.message ?? `Entrez votre ${input.label ?? input.name}, svp !`
        throw errorMessage
      }

    })
  }

  inputLIstValidator(inputList: I_InputError[], formName: any) {
    const error: any = {}
    inputList.forEach((input: I_InputError) => {
      const invalidInput = formName.get(input.name)?.invalid
      if (invalidInput) {
        const errorMessage = input.message ?? `Entrez votre ${input.label ?? input.name}, svp !`
        if (input.name) error[input.name] = errorMessage
      }
    })
    const isEmpty = Object.keys(error).length === 0
    if (!isEmpty) throw error
  }

  emptyInput(input: any, message: I_InputError) {
    if (!input) {
      let errorMessage: any = {}
      const { label } = message
      label ? errorMessage[label] = message.message : errorMessage = message.message
      throw errorMessage
    }
  }

  validateEmailFormat(formName: any) {
    const value = formName.get("email").value
    const isDadFormat = formName.get("email")?.invalid
    if (value && isDadFormat) {
      throw "l'adresse mail n'est pas correcte"
    }
  }

  comparePassword(password: string, confirmePassword: string) {
    if (password !== confirmePassword) throw "Les deux mots de passe ne sont pas les mêmes."
  }

  comparePhoneNumber(newPhoneNumber: string, oldPhoneNumber: string) {
    if (newPhoneNumber == oldPhoneNumber) throw { phoneNumber: "Les deux numéros de téléphone sont identiques." }
  }

  matriculeFormatValidator(matricule: string) {
    if (!matricule) return
    if (!MATRICULE_REGEX.test(matricule)) throw { matricule: "Le numero matricule n'est pas correcte" }
  }

  excelFileValidator(fileName: string) {
    const [name, extention] = fileName.split(".")
    if (extention !== "xlsx") {
      throw { message: `Le format ${extention} n'est pas autorisé. Choisisser un ficher xlsx` }
    }
  }

  emailFormatValidator(formName: any) {
    const value = formName.get("email").value
    const isDadFormat = formName.get("email")?.invalid
    if (!value) return
    if (value && isDadFormat) {
      throw { email: "l'adresse mail n'est pas correcte" }
    }
  }

  phoneFormatValidator(phoneNumber: string, label: string | null = null) {
    const error: any = {}
    if (!phoneNumber) return
    if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      error[label ?? "phoneNumber"] = "Le format du numéro de téléphone n'est pas correct"
      throw error
    }
  }

  schoolYearValidator(schoolYear: string) {
    const [first, second] = schoolYear.split("-")
    if (!first && !second) return
    const isEgalToOne = Number(second) - Number(first)
    if (isEgalToOne !== 1) throw { schoolYear: "Assurez-vous que les années se suivent (EX: 2022-2023)" }
  }

  inputValidatorNumber(value: number, limit: number, message: I_InputError) {
    if (!value) return
    if (value > limit) {
      const error: any = {}
      const errorMessage = message.message
      error[message.name!] = errorMessage
      throw error
    }
  }

  imageFileExtentionValidator(fileName: string) {
    const extentionList = ['png', 'jpg', 'jpeg']
    const [name, extention] = fileName.split(".")
    const extentionNotAllowed = !extentionList.includes(extention)
    if (extentionNotAllowed) {
      throw { message: `Le format ${extention} n'est pas autorisé. Choisisser un ficher ${extentionList}` } 
    }
  }

}
