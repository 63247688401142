<div class="container-auth">
    <div class="wrapper">
        <div class="login box__auth">
            <div class="content-heading">
                <div class="y-style">
                    <div class="logo-auth mb-5 mt-2">
                        <img src=".../../../../../assets/images/LOGO_ERSYS-WHITE.png" alt="logo">
                    </div>
                </div>
            </div>

            <div class="content-form">
                <div class="y-style">
                    <h1>{{user.firstname}} {{user.firstname}},
                    </h1>
                    <div class="justify"> Vous avez récemment demandé à réinitialiser le mot de passe de votre compte ERSYS. Veuillez
                        verifier votre adresse mail <strong>{{user.email}}</strong>, nous vous avons envoyé un lien de réinitialisation de compte.</div>
                </div>
            </div>
        </div>

    </div>
</div>