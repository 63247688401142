<p-toast position="top-center"></p-toast>
<div class="container-auth">
  <div class="wrapper">

    <div class="login box__auth">
      <div class="content-heading">
        <div class="y-style">
          <div class="logo-auth mb-5 mt-2">
            <img src=".../../../../../assets/images/LOGO_ERSYS-WHITE.png" alt="logo">
          </div>
          <div class="welcome">
            <h2>
              Bienvenue
            </h2>
            <p>
              Veuillez entrer vos paramètres de connexion.
            </p>
          </div>
        </div>
      </div>

      <div class="content-form">
        <div class="y-style">
          <form [formGroup]="login">
            <div>
              <label for="email">Email</label>
              <input type="email" placeholder="Entrer votre email" id="email" formControlName="username" />
            </div>

            <div>
              <label for="password">Mot de passe</label>
              <input type="password" placeholder="Entrer votre mot de passe" id="password" formControlName="password" />
            </div>

            <button class="custom-button" *ngIf="!isLoader" type="button" (click)="runLogin()">Se connecter</button>
            <app-loader *ngIf="isLoader"></app-loader>

            <div class="forgot">
              <a (click)=goToForGotPassWord()>Mot de passe oublié ?</a>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>