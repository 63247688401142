<p-dialog header="Envoyer une invitation" [(visible)]="displayModal" [position]="position" [modal]="true"
    [style]="{width: '70vw',height:'100vh'}" [draggable]="false" [resizable]="false">
    <form class="mt-5" [formGroup]="invitation">
        <span class="p-float-label mb-1">
            <input id="float-input" type="email" pInputText formControlName="email">
            <label for="float-input">Email</label>
        </span>
        <div class="mb-1">
            <p-dropdown [options]="roles" formControlName="profile" (onChange)="onSeleteRole()" optionValue="ids"
                optionLabel="profile" [filter]="true" placeholder="Selectionner un rôle" filterBy="profile"
                [style]="{'width':'100%'}"></p-dropdown>
        </div>
        <div class="mb-5" *ngIf="rolesDetailList.length > 0">
            <p-dropdown [options]="rolesDetailList" formControlName="detail" optionValue="ids" (change)="onSeleteRole()"
                optionLabel="longlabel" [filter]="true" placeholder="Selectionner un detail du rôle"
                filterBy="longlabel" [style]="{'width':'100%'}"></p-dropdown>
        </div>
        <div *ngIf="!isLoader" class="mt-5"><button class="custom-button" type="button" (click)="sendInvitation()">Soummettre
                l'invitation</button></div>
        <app-loader *ngIf="isLoader"></app-loader>
    </form>
</p-dialog>

<p-toast position="top-center"></p-toast>


<div class="mt-10"></div>
<h1 class="mb-3">Comptes Utilisateurs</h1>

<h2>Inviter des personnes à creer leurs compte</h2>

<div class="btn-search mt-2" (click)="showPositionDialog('top')">Envoyer une invitation</div>


<div class="container-table">
    <table>
        <thead>
            <tr>

                <th>Nom</th>
                <th>Email</th>
                <th>Etablissements </th>
                <th>Roles</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let guestUser of guestUsers | paginate: { itemsPerPage: page.sizes, currentPage: page.current, totalItems:page.count }; let i = index">
                <td>
                    {{guestUser.firstname}} {{guestUser.lastname}}
                </td>
                <td>{{guestUser.email}}</td>
                <td>{{guestUser.longlabel}}</td>
                <td>{{guestUser.roles}}</td>
                <td class="primary btn-width-1" *ngIf="guestUser.is_disabled; else desactivateBtn">
                    <div class="btn-status">Activer</div>
                </td>
                <ng-template #desactivateBtn>
                    <td class="primary btn-width-1">
                        <div class="btn-status">Desactiver</div>
                    </td>
                </ng-template>
            </tr>
            <tr *ngIf="guestUsers.length === 0">
                <td colspan="10" class="center-td"><app-loader></app-loader></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination mb-5" *ngIf="guestUsers.length > 1">
        <pagination-controls (pageChange)="OnPageChanged($event)" previousLabel="Précedent" nextLabel="Suivant"
            class="mt-1"></pagination-controls>

    </div>
</div>