import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { I_StandartList } from 'src/app/utils/type.util';
import { C_ApiBaseUrl, C_ApiKey_SHARE, C_ApiShareBaseUrl } from 'env/env';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getRoles(): Observable<any> {
    return this.httpClient.get(`${C_ApiShareBaseUrl}.user.profile/?apikey=${C_ApiKey_SHARE}`);
  }

  getBankList(): Observable<any> {
    return this.httpClient.get(`${C_ApiShareBaseUrl}.bank/?apikey=${C_ApiKey_SHARE}`);
  }

  async getRegionalBranches(): Promise<I_StandartList[]> {
    try {
      const httpReponse = this.httpClient.get(`${C_ApiShareBaseUrl}.regional.branches/?apikey=${C_ApiKey_SHARE}`);
      const regionalBramches = await firstValueFrom(httpReponse)
      return regionalBramches as I_StandartList[]
    } catch (error) {
      throw error
    }
  }


  getUserConnected(token: string): Observable<any> {
    return this.httpClient.get(`${C_ApiBaseUrl}.get.user.dashboard.connected/?token=${token}`);
  }

  getSchools(): Observable<any> {
    return this.httpClient.get(`${C_ApiShareBaseUrl}.school/?apikey=${C_ApiKey_SHARE}`);
  }



  async sendInvitation(params: any): Promise<any> {
    try {
      const httpReponse = this.httpClient.post(`${C_ApiBaseUrl}.sending.email.registration/`, params);
      const respnose = await firstValueFrom(httpReponse)
      return respnose
    } catch (error) {
      throw error
    }
  }


  register(params: any): Observable<any> {
    return this.httpClient.post(`${C_ApiBaseUrl}.create.user/`, params);
  }

 
  async login(params: any): Promise<any> {
    const httpResponse = await this.httpClient.post(`${C_ApiBaseUrl}.authorize.user.token/`, params);
    const response = firstValueFrom(httpResponse)
    return response
  }

  logout(token: string): Observable<any> {
    return this.httpClient.get(`${C_ApiBaseUrl}.logout/?token=${token}`);
  }

}
