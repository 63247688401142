import { Component } from '@angular/core';
import { C_Loader } from 'src/app/utils/constant.util';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  loader = C_Loader
}
