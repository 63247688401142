import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SendEmailGuard } from 'src/app/guards/send-email/send-email.guard';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { UserService } from 'src/app/services/user/user.service';
import { C_LocalStorage } from 'src/app/utils/constant.util';
import { toastTypes } from 'src/app/utils/enum.util';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent {
  constructor(
    private userService: UserService,
    private router: Router,
    private sendEmailGuard: SendEmailGuard,
    private messageService: MessageService,
    private localStorageService: LocalStorageService) { }

  regex = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"

  login = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.pattern(this.regex)]),
    password: new FormControl("")
  })

  isLoader = false

  goToForGotPassWord() {
    this.sendEmailGuard.canActiveRoute(true)
    this.router.navigate(["send-email"])
  }

 
  async runLogin(): Promise<void> {
    try {
      this.isLoader = true
      this.InputValidation()
      const userConnected = await this.userService.login(this.login.value)
      this.isLoader = false
      if (userConnected.forcepassword) {
        this.localStorageService.save(C_LocalStorage.reset, userConnected)
        this.router.navigate(['verify-otp'])
        return
      }
      this.localStorageService.save(C_LocalStorage.token, userConnected.access_token)
      this.router.navigate(['dashboard'])
    } catch (error: any) {
      this.isLoader = false
      if (error.error) {
        this.displayToastError(error.error.detail.detail)
      }
      this.displayToastError(error)
    }
  }

  InputValidation(): void {
    this.isLoader = false
    const validator = this.login.value

    if (!validator.username) {
      this.displayToastError("l'email est requis")
      throw ''
    }

    if (validator.username) {
      if (this.login.get('username')?.invalid) {
        this.displayToastError("le format du mail n'est pas correcte")
        throw ''
      }
    }

    if (!validator.password) {
      this.displayToastError("le password est requis")
      throw ''
    }

  }

  displayToastError(message: string) {
    this.messageService.add({ severity: toastTypes.error, summary: 'ERREUR', detail: message });
  }

}
