import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ResetPasswordGuard } from 'src/app/guards/reset-password/reset-password.guard';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HandlerErrorService } from 'src/app/services/handler-error/handler-error.service';
import { E_toast } from 'src/app/utils/enum.util';
import { I_InputError } from 'src/app/utils/type.util';

@Component({
  selector: 'app-send-reset-password-email',
  templateUrl: './send-reset-password-email.component.html',
  styleUrls: ['./send-reset-password-email.component.css']
})
export class SendResetPasswordEmailComponent {
  constructor(private router: Router,
    private handlerErrorService: HandlerErrorService,
    private messageService: MessageService,
    private resetPasswordGuard: ResetPasswordGuard,
    private authService: AuthService
  ) { }

  form = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email])
  })

  isLoader = false

  displayToastError(message: string) {
    this.messageService.add({ severity: E_toast.error, summary: 'ERREUR', detail: message });
  }

  displayToastSuccess(message: string) {
    this.messageService.add({ severity: E_toast.success, summary: 'SUCCES', detail: message });
  }

  goToResetPassWord(data: any) {
    this.resetPasswordGuard.canActiveRoute(true)
    const encodedString = encodeURIComponent(JSON.stringify(data));
    this.router.navigate(["message"], { queryParams: { data: encodedString } })
  }


  async sendResetPasswordEmail() {
    try {
      this.isLoader = true
      const { email } = this.form.value
      this.handlerErrorService.validateEmailFormat(this.form)
      this.handlerErrorService.emptyInput(this.form.value.email, { message: "Entrer votre adresse mail" })
      if (email) {
        const response = await this.authService.sendResetPasswordEmail(email)
        this.form.reset()
        this.isLoader = false
        this.goToResetPassWord(response.data)
      }
    } catch (error: any) {
      this.isLoader = false
      if (error.error) return this.displayToastError(error.error.detail?.message)
      this.displayToastError(error as string)
    }
  }
}
