import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  save(key: string, value: unknown):void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  value(key:string){
    let value
    const getValue = localStorage.getItem(key);
    if(getValue) value = JSON.parse(getValue);
    return value
  }

  remove(key:string):void{
    localStorage.removeItem(key)
  }
}
