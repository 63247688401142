<div class="container-dashboard">
    
    <aside>

        <div class="profile">
            <img src=".../../../../../assets/images/LOGO_ERSYS_PRIMARY.png" alt="profile_picture">
        </div>

        <p-panelMenu [model]="items" [style]="{'width':'230px'}"></p-panelMenu>

    </aside>
    <!----------------- END Of ASIDE ---------------->

    

    <main>
        <div class="header">
                <h1 class="mb-3 header_title">{{userConnected.longlabel}}</h1>
            <div>
                <div (click)="runLogout()" class="logout">
                    <i class="pi pi-power-off"></i>
                    <h3>Se déconnecter</h3>
                </div> 
            </div>
          </div>
        <router-outlet></router-outlet>
    </main>
    <!----------------- END Of MAIN ---------------->

</div>