import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HandlerErrorService } from 'src/app/services/handler-error/handler-error.service';
import { E_toast } from 'src/app/utils/enum.util';
import { I_InputError } from 'src/app/utils/type.util';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {

  constructor(private router: Router,
    private handlerErrorService: HandlerErrorService,
    private messageService: MessageService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params['resetPasswordToken']) {
      const resetPasswordToken = this.activatedRoute.snapshot.params['resetPasswordToken']
      const jwtHelper = new JwtHelperService()
      const decodeInformation = jwtHelper.decodeToken(resetPasswordToken)
      const { email } = decodeInformation.data
      this.resetUserInformation = decodeInformation.data
      this.resetPassword.patchValue({ email })
    }
  }

  isLoader = false

  inputList: I_InputError[] = [
    { name: "pwd", label: "mot de passe" },
    { name: "pwd_confirm", message: "Confirmer votre mot de passe" },
    { name: "email", label: "adresse mail" },
  ]

  resetUserInformation:any = {}
  resetPassword = new FormGroup({
    pwd: new FormControl("", Validators.required),
    pwd_confirm: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email])
  })

  displayToastError(message: string) {
    this.messageService.add({ severity: E_toast.error, summary: 'ERREUR', detail: message });
  }

  displayToastSuccess(message: string) {
    this.messageService.add({ severity: E_toast.success, summary: 'SUCCES', detail: message });
  }

  async runResetPassword() {
    try {
      this.isLoader = true
      const { email, pwd, pwd_confirm } = this.resetPassword.value
      this.handlerErrorService.validateEmailFormat(this.resetPassword)
      this.handlerErrorService.validateInputs(this.inputList, this.resetPassword)
      if (email && pwd && pwd_confirm) {
        this.handlerErrorService.comparePassword(pwd, pwd_confirm)
        await this.authService.resetPassword({ email, pwd, pwd_confirm })
        this.isLoader = false
        this.resetPassword.reset()
        this.router.navigate(['login'])
      }
    } catch (error) {
      this.isLoader = false
      this.displayToastError(error as string)
    }
  }

}
