<p-toast position="top-center"></p-toast>
<div class="container-auth">
  <div class="wrapper">

    <div class="login box__auth">
      <div class="content-heading">
        <div class="y-style">
          <div class="logo-auth mb-5 mt-3">
            <img src=".../../../../../assets/images/LOGO_ERSYS-WHITE.png" alt="logo">
          </div>
          <div class="welcome">
            <h2>
              Créer<br />
              un compte
            </h2>
            <p>
              la création de compte se fait en un clic !
            </p>
          </div>
        </div>
      </div>

      <div class="content-form">
        <div class="y-style">
          <form [formGroup]="register">
            <div>
              <label for="nom">Nom</label>
              <input type="text" placeholder="Entrez votre nom" id="nom" formControlName="firstname">
            </div>

            <div>
              <label for="prenom">Prenom</label>
              <input type="text" placeholder="Entrez votre nom" id="prenom" formControlName="lastname" />
            </div>

            <div class="mb-1">
              <label for="passwordConfirm">mot de passe</label>
              <p-password formControlName="pwd" [toggleMask]="true" weakLabel="faible" mediumLabel="moyen"
              promptLabel="Entrez votre mot de passe"
                strongLabel="fort" [style]="{width: '25vw',border:'0.5px solid #888',borderRadius:'7px'}">
                <ng-template pTemplate="header">
                  <h3>Niveau de sécurité</h3>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="mt-1">Le mot de passe doit contenir :</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>Au moins une minuscule ([a-z])</li>
                    <li>Au moins une majuscule ([A-Z])</li>
                    <li>Au moins un chiffre ([0-9])</li>
                    <li>Au moins un caractère spécial ([#?!@$%^&*-])</li>
                    <li>8 caractères au minimum, ex :12@tR3n#</li>
                  </ul>
                </ng-template>
              </p-password>
            </div>

            <div class="mb-1">
              <label for="passwordConfirm">Repetez le mot de passe</label>
              <p-password formControlName="pwd_confirm" [toggleMask]="true" weakLabel="faible" mediumLabel="moyen"
              promptLabel="Repetez le mot de passe"
                strongLabel="fort" [style]="{width: '25vw',border:'0.5px solid #888',borderRadius:'7px'}">
                <ng-template pTemplate="header">
                  <h3>Niveau de sécurité</h3>
                </ng-template>
              </p-password>
            </div>


            <button class="custom-button" *ngIf=!isLoader type="button" (click)="runRegister()">Crée votre compte</button>
            <app-loader *ngIf=isLoader></app-loader>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>