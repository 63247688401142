import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ResetPasswordGuard } from 'src/app/guards/reset-password/reset-password.guard';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HandlerErrorService } from 'src/app/services/handler-error/handler-error.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { C_LocalStorage } from 'src/app/utils/constant.util';
import { E_toast } from 'src/app/utils/enum.util';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  form = new FormGroup({
    otp: new FormControl("", [Validators.required])
  })

  emailFromLocalStorage: string = ""
  isLoader = false

  constructor(private router: Router,
    private handlerErrorService: HandlerErrorService,
    private messageService: MessageService,
    private resetPasswordGuard: ResetPasswordGuard,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.emailFromLocalStorage = this.localStorageService.value(C_LocalStorage.reset)?.email
  }

  displayToastError(message: string) {
    this.messageService.add({ severity: E_toast.error, summary: 'ERREUR', detail: message });
  }

  displayToastSuccess(message: string) {
    this.messageService.add({ severity: E_toast.success, summary: 'SUCCES', detail: message });
  }



  async verifyOTP() {
    try {
      this.isLoader = true
      const { otp } = this.form.value
      this.handlerErrorService.emptyInput(otp, { message: "Saisissez l'OTP reçu par mail SVP" })
      if (otp) {
        const response = await this.authService.verifyOTP({ otp, email: this.emailFromLocalStorage })
        this.form.reset()
        this.isLoader = false
        this.resetPasswordGuard.canActiveRoute(true)
        this.router.navigate(["update-password"])
      }
    } catch (error: any) {
      this.isLoader = false
      if (error.error) return this.displayToastError(error.error.detail?.message)
      this.displayToastError(error as string)
    }
  }

}
