<p-toast position="top-center"></p-toast>

<div class="container-auth">
    <div class="wrapper">

        <div class="login box__auth">
            <div class="content-heading">
                <div class="y-style">
                    <div class="logo-auth mb-5 mt-2">
                        <img src=".../../../../../assets/images/LOGO_ERSYS-WHITE.png" alt="logo">
                    </div>
                    <div class="welcome">
                        <h2>
                            Vérification
                        </h2>
                    </div>
                </div>
            </div>

            <div class="content-form">
                <div class="y-style">
                    <form [formGroup]=form>
                        <div class="justify mb-2">Un OTP a été envoyé à cette adresse <strong>{{emailFromLocalStorage}}</strong></div>
                        <div>
                            <label for="otp">Saisissez l'OTP reçu par mail</label>
                            <p-inputMask mask="9999" formControlName="otp" ></p-inputMask>
                        </div>

                        <button class="custom-button" *ngIf=!isLoader type="button" (click)="verifyOTP()">Vérification</button>
                        <app-loader *ngIf=isLoader></app-loader>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>