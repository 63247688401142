import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/services/user/user.service';
import { toastTypes } from 'src/app/utils/enum.util';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers:[MessageService]
})
export class RegisterComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private userService: UserService, private messageService: MessageService) { }
  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params['token']) {
      const token = this.activatedRoute.snapshot.params['token']
      const jwtHelper = new JwtHelperService()
      const decodeUserInformation = jwtHelper.decodeToken(token)
      this.register.patchValue({ email: decodeUserInformation.data })
    }
  }

  register = new FormGroup({
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    email: new FormControl(''),
    pwd: new FormControl(''),
    pwd_confirm: new FormControl(''),
  })

  isLoader = false

  runRegister(): void {
    this.isLoader = true
    this.InputValidation()
    const registerData = {
      ...this.register.value,
      school: null,
      regionalbranch: null,
      bank: null,
      is_disabled: true
    }
    this.userService.register(registerData).subscribe({
      next: (user) => {
        this.isLoader = false
        this.router.navigate(['login'])
      }, error: (err) => {
        this.displayToastError(err.error.detail[0].msg)
      },
    })
  }


  InputValidation(): void {
    this.isLoader = false
    const validator = this.register.value

    if (!validator.firstname) {
      this.displayToastError("le nom  est requis")
      return
    }

    if (!validator.lastname) {
      this.displayToastError("le prenom est requis")
      return
    }

    if (!validator.pwd) {
      this.displayToastError("le mot de passe est requis")
      return
    }

    if (!validator.pwd_confirm) {
      this.displayToastError("confirmé le mot de passe")
      return
    }

    if (validator.pwd_confirm !== validator.pwd) {
      this.displayToastError("Les mots de passe ne sont les mêmes")
      return
    }

  }

  displayToastError(message: string) {
    this.messageService.add({ severity: toastTypes.error, summary: 'ERREUR', detail: message });
  }

}
