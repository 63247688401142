import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password-message',
  templateUrl: './reset-password-message.component.html',
  styleUrls: ['./reset-password-message.component.css']
})
export class ResetPasswordMessageComponent {
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.queryParamMap.get("data")
    if (routeParams) {
      const objectString = decodeURIComponent(routeParams);
      const convertStringToObject = JSON.parse(objectString)
      this.user = convertStringToObject
    }
  }

  user: any = {}
}
