export enum E_SearchOption {
  multiple = "multiple",
  matricule = "matricule",
  status = "status",
}


export enum E_toast {
  error = "error",
  success = "success",
  info = "info",
  warning = "warn"
}


export enum E_Diploma {
  BP1 = "BP1",
  BP2 = "BP2",
  BP3 = "BP3",
}

export enum E_School_Type {
  PRIVE = 1,
  PUBLIQUE = 2
}



export enum E_roles_Name {
  R_DOBM_ADMIN = "R_DOBM_ADMIN",
  R_DEXC_ADMIN = "R_DEXC_ADMIN",
  ROLE_SCHOOL_USER = "R_SCHOOL_USER",
  ROLE_BANK_USER = "R_BANK_USER",
  ROLE_ADMIN = "R_ADMIN",
  ROLE_REGIONAL_USER = "R_REGIONAL_ADMIN",
  R_DEEP_ADMIN = "R_DEEP_ADMIN",
  R_DEP_ADMIN = "R_DEP_ADMIN",
  R_DGFI_ADMIN = "R_DGFI_ADMIN",
  R_DPSI_ADMIN = "R_DPSI_ADMIN"
}


export enum toastTypes {
  error = "error",
  success = "success"
}

export enum ValidateCapacityOpinion {
  unfavorable = 2,
  favorable = 1
}
