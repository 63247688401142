import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/services/admin/admin.service';
import { UserService } from 'src/app/services/user/user.service';
import { C_pageSize, C_RegexEmail } from 'src/app/utils/constant.util';
import { E_roles_Name, E_toast } from 'src/app/utils/enum.util';
import { I_role, I_StandartList, I_UserGuestType } from 'src/app/utils/type.util';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [MessageService]
})
export class AdminComponent implements OnInit {

  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.runGetGuestUsers()
    this.runGetRoles()
    this.runGetSchools()
    this.runGetRegionalBranches()
    this.runGetBankList()

  }


  roles: I_role[] = []
  schools: I_StandartList[] = []
  banks: I_StandartList[] = []
  regionalBranches: I_StandartList[] = []
  rolesDetailList: I_StandartList[] = []
  guestUsers: I_UserGuestType[] = []
  profileSelected: I_role = {} as I_role

  activePageIndex = 0
  page = {
    current: 1,
    count: this.guestUsers.length,
    sizes: C_pageSize,
    step: []
  }

  isLoader: boolean = false

  displayPosition: boolean = false;
  displayModal: boolean = false;
  position: string = '';


  invitation = new FormGroup(
    {
      email: new FormControl('', [Validators.required, Validators.pattern(C_RegexEmail)]),
      profile: new FormControl('', Validators.required),
      detail: new FormControl('', Validators.required),
    }
  )

  displayToastError(message: string) {
    this.messageService.add({ severity: E_toast.error, summary: 'ERREUR', detail: message });
  }

  displayToastSuccess(message: string) {
    this.messageService.add({ severity: E_toast.success, summary: 'SUCCES', detail: message });
  }


  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
    this.displayModal = true
  }

  OnPageChanged(event: any) {
    this.activePageIndex = (event - 1) * C_pageSize
    this.page.current = event
    this.runGetGuestUsers(this.activePageIndex)
  }

  runGetGuestUsers(currentPage?: number): void {
    this.adminService.getGuestUsers(currentPage).subscribe({
      next: (userList) => {
        this.guestUsers = userList
        this.page.count = this.guestUsers[0].total_rows
      }, error: (err) => {
        console.log("err", err)
      },
    })
  }

  runGetRoles() {
    this.userService.getRoles().subscribe({
      next: (roleList) => {
        this.roles = roleList
      }, error: (err) => {
        console.log("err", err)
      },
    })
  }
  runGetSchools() {
    this.userService.getSchools().subscribe({
      next: (schoolList: any[]) => {
        this.schools = schoolList.map(item => {
          const creatNewSchoolList: I_StandartList = { ids: item.school_id, longlabel: item.school_label }
          return creatNewSchoolList
        })
      }, error: (err) => {
        console.log("err", err)
      },
    })
  }
  runGetBankList() {
    this.userService.getBankList().subscribe({
      next: (bankList) => {
        this.banks = bankList
      }, error: (err) => {
        console.log("err", err)
      },
    })
  }

  async runGetRegionalBranches() {
    try {
      this.regionalBranches = await this.userService.getRegionalBranches()
    } catch (error) {
      console.log("err", error)
    }
  }

  sendInvitation(): void {
    try {
      this.isLoader = true
      this.InputValidation()
      this.sendEmail()
    } catch (error) {
      this.isLoader = false
      this.displayToastError(error as string)
    }
  }

  InputValidation(): void {
    const validator = this.invitation.value
    this.invitation.get('email')?.invalid

    if (!validator.email) {
      throw 'email is empty'
    }
    if (validator.email) {
      if (this.invitation.get('email')?.invalid) {
        throw "le format du mail n'est pas correcte"
      }
    }

    if (!validator.profile) {
      throw 'role is empty'
    }


    if (this.rolesDetailList.length > 0 && E_roles_Name.ROLE_SCHOOL_USER === this.profileSelected.profile) {
      if (!validator.detail) {
        throw 'school is empty'
      }
    }
    if (this.rolesDetailList.length > 0 && E_roles_Name.ROLE_BANK_USER === this.profileSelected.profile) {

      if (!validator.detail) {
        throw 'bank is empty'
      }
    }

  }

  async sendEmail(): Promise<void> {
    try {

      const invitationRequest = {
        ...this.invitation.value,
        firstname: null,
        lastname: null,
        pwd: "12@GhostR3con#",
        pwd_confirm: null,
        school: this.profileSelected.profile === E_roles_Name.ROLE_SCHOOL_USER ? this.invitation.value.detail : null,
        regionalbranch: this.profileSelected.profile === E_roles_Name.ROLE_REGIONAL_USER ? this.invitation.value.detail : null,
        bank: this.profileSelected.profile === E_roles_Name.ROLE_BANK_USER ? this.invitation.value.detail : null,
        is_disabled: true
      }

      await this.userService.sendInvitation(invitationRequest)
      this.displayToastSuccess(`Une invitation a été envoyé à l'adresse suivante (${this.invitation.value.email})`)
      this.invitation.reset()
      this.displayModal = false
      this.isLoader = false
    } catch (error: any) {
      this.isLoader = false
      const serverError = error.error.detail
      const { message } = serverError
      if (message) {
        this.displayToastError(message)
        return
      }
      this.displayToastError(error)
    }

  }



  async onSeleteRole(): Promise<void> {
    const { profile } = this.invitation.value
    this.profileSelected = this.roles.find((profileName: I_role) => profileName.ids === profile)!

    if (E_roles_Name.ROLE_SCHOOL_USER === this.profileSelected.profile) {
      this.rolesDetailList = this.schools
      return
    }
    if (E_roles_Name.ROLE_BANK_USER === this.profileSelected.profile) {
      this.rolesDetailList = this.banks
      return
    }
    if (E_roles_Name.ROLE_REGIONAL_USER === this.profileSelected.profile) {
      this.rolesDetailList = await this.userService.getRegionalBranches()
      return
    }
    this.rolesDetailList = []
  }


}
